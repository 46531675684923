.wrapper {
  display: none !important;
}

.wrapper-open {
  position: fixed;
  display: flex;
  margin: 0;
  padding: 0;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  animation: open 1s forwards;
  transition: 0.5s all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper-closed {
  position: fixed;
  display: flex;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  animation: close 1s forwards;
  transition: 0.5s all;
}

@keyframes open {
  from {
    top: 100%;
    transform: scale(1);
  }
  to {
    top: 0;
    transform: scale(3);
  }
}

@keyframes close {
  from {
    top: 0;
    transform: scale(3);
  }
  to {
    top: 100%;
    transform: scale(1);
  }
}

.navLinks {
  opacity: 0;
  display: none;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  text-align: center;
  transition: 2s all;
}
