body,
html {
  margin: 0 !important;
  padding: 0 !important;
  color: #112b46 !important;
  font-family: "Nunito", sans-serif !important;
}

.scroll::-webkit-scrollbar {
  width: 12px !important;
  border-radius: 0.8em;
  background-color: #f5f5f5 !important;
  cursor: pointer !important;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 0.8em;
  background-color: #f28915 !important;
  height: 10px !important;
  position: absolute;
  cursor: pointer !important;
}

.noScroll::-webkit-scrollbar {
  display: none !important; /* Chrome, Safari, Opera */
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
  
}
.scroll_White::-webkit-scrollbar {
  width: 12px !important;
  border-radius: 0.8em;
  background-color: rgb(245, 245, 245, 0.4) !important;
  cursor: pointer !important;
}

.scroll_White::-webkit-scrollbar-thumb {
  border-radius: 0.8em;
  background-color: #ffffff !important;
  height: 10px !important;
  position: absolute;
  cursor: pointer !important;
}

a.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease-in-out;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  float: left;
  width: 2.5rem;
  height: 2.5rem;
}

a.menu-icon .lines {
  background-color: white;
  border-radius: 2px;
  display: block;
  height: 2px;
  position: relative;
  transition: all 0.2s ease 0s;
  width: 16px;
}

a.menu-icon .lines:before,
a.menu-icon .lines:after {
  background-color: white;
  border-radius: 2px;
  content: "";
  display: inline-block;
  height: 2px;
  left: 0;
  position: absolute;
  transition: 0.3s;
  width: 16px;
}

a.menu-icon .lines:before {
  top: 6px;
}

a.menu-icon.open .lines {
  background-color: transparent;
}

a.menu-icon.open .lines:before {
  transform: rotate(45deg);
}

a.menu-icon.open .lines:after {
  transform: rotate(-45deg);
}

a.menu-icon.open .lines:before,
a.menu-icon.open .lines:after {
  top: 0;
}

a.menu-icon .lines:after {
  top: -6px;
}

ul {
  list-style: none;
  color: white;
}

ul li a {
  color: white !important;
  text-decoration: none;
}

a,
a:visited,
a:focus,
a:active,
a:hover {
  outline: 0 none !important;
}

:focus,
:active {
  outline: 0;
  border: 0;
}

.b-radius {
  border-radius: 0.8em !important;
}

.modal-content {
  border-radius: 0.9em !important;
}

.downloadEvent {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: fit-content;
  height: fit-content;
  border-radius: 0.4rem;
  padding: 5px 10px;
  background: linear-gradient(to right, #FFBB00, #D87516);
  color: white;
  text-decoration: none !important;
  border: 0 !important;
  cursor: pointer;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}

.downloadEvent:hover{
  text-decoration: none !important;
  color: white
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

:root {
  --toastify-color-light: #fff !important;
  --toastify-color-dark: #121212 !important;
  --toastify-color-info: #3498db !important;
  --toastify-color-success: linear-gradient(
    to right,
    #d87516,
    #ffbb00
  ) !important;
  --toastify-color-warning: #f1c40f !important;
  --toastify-color-error: #e74c3c !important;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: #d87516 !important;
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #112b46 !important;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
